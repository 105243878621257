import React from "react";

import "./style.scss";
import { Contact, LightBox } from "../../components";
import { getImage } from "../../helper";

const images = [
  "/images/lancelot.jpg",
  "/images/redman.jpg",
  "/images/tidbit.jpg",
];

export const AboutLayout = ({ title = "", contact = "" }) => (
  <div className="about">
    {title && <h1>{title}</h1>}
    <div className="data">
      <div className="quickLinks">
        <a href="#basics">Basics</a>
        <a href="#history">History</a>
        <a href="#colors">Colors</a>
        <a href="#markings">Markings</a>
        <a href="#fading">Fading</a>
        <a href="#patterns">Patterns</a>
      </div>

      <h3 id="basics">The Basics</h3>
      <p>
        Shih Tzus are one of the happiest fur creatures on earth. They are full
        of love and those beautiful tails seldom stop wagging. You will notice a
        little bounce to their gait that is truly delightful. They live for your
        companionship and do their best to please you. The Shih Tzu personality
        is affectionate, outgoing and playful. As a family pet they are ideal
        inside residents. Shedding is mild to none so you are not glued to the
        vacuum to keep your house clean. If you are thinking about a new furry
        family member you will want to consider one of these gorgeous babies.
        You will love their silky thick coats that come in so many colors and
        markings. They have eyes that will melt your heart and love that will
        sustain you throughout their lifetime.
      </p>

      <h3 id="history">Breed History</h3>
      <p>
        We do not have exact information on the Shih Tzu breed but the following
        historical accounts are the most widely accepted belief of when and
        where these beautiful animals originated.
      </p>
      <p>
        Documentation and artistic representations of Shih-Tzu-like dogs are
        found dating back to A.D. 624, although the breed’s introduction into
        China most likely occurred many hundreds of years later. It is well
        documented that they were favorite house pets of the Ming Dynasty (from
        1368 to 1644), and then in the mid-17th century, dogs were brought to
        the Chinese court from Tibet. These specimens proliferated in the
        Forbidden City of Peking for hundreds of years, evolving into the modern
        version of the Shih-Tzu. The breed was so revered that long after
        trading with the West was established, the Chinese refused to sell
        specimens of the breed to outsiders, or even give them as gifts. After
        the communist revolution in China, breeding of any pure breed virtually
        ceased and the Shih-Tzu was nearly extinct. Several of these individuals
        were exported to England in 1930, saving the breed from extinction.
      </p>
      <p>
        It has been said that American Soldiers stationed in Europe and
        Australia brought the breed back with them to the U.S., paving the way
        for the Shih-Tzu to be accepted in the American Kennel Club in 1969.
      </p>
      <i>
        **The breed history, paragraph two, is included with permission from the
        American Kennel Club.
      </i>

      <h3 id="colors">Shih Tzu Colors</h3>
      <p>
        Shih Tzu dogs come in an amazing array of rich colors with all colors
        considered good and equally desirable. Puppies can be born with a solid
        coat of hair, a combination of two colors, or a mixture of three colors.
      </p>
      <p>
        The solid colors are black, white, gold, red, silver, brindle, liver and
        blue.
      </p>
      <p>
        Liver is confusing because it is the color of the points (the dog’s
        nose, eye rims, pads, and lips and not the actual coat color). That
        means the dog must have brown or liver pigmentation to be called
        “liver.” You will see liver dogs that have coat colors ranging from
        white to deep chocolate. Sometimes the liver colored dogs will have
        green (or human like) eyes in contrast with the deep brown eyes and
        black pigmentation you see in other Shih Tzus.
      </p>
      <p>
        Blue can be confusing too. A true blue Shih Tzu will have a blue nose,
        but can have other colors of hair. The color ranges from white to dark
        charcoal and is often difficult to see indoors. It is best to go into
        the sunlight to see the blue because at a distance it can appear to be
        black.
      </p>
      <p>
        There are seven varieties of colors that contain Two Color Combinations,
        a white with another color:
      </p>
      <ol>
        <li>White with black</li>
        <li>White with blue</li>
        <li>White with silver</li>
        <li>White with red</li>
        <li>White with gold</li>
        <li>White with brindle</li>
        <li>White with liver</li>
      </ol>
      <p>Shih Tzu dogs can also have a three color combination:</p>
      <ol>
        <li>Silver, Gold and White</li>
        <li>Silver, Black and White</li>
        <li>Black, Gold and White</li>
        <li>Black, Silver and Gold</li>
      </ol>

      <h3 id="markings">Unique Markings</h3>
      <p>
        Black Mask, Black Tipping, Dobie Markings, and Eye Stripes. (Eye stripes
        are dark shading from the corner of the eyes that resemble an eyebrow).
      </p>

      <h3 id="fading">Fading</h3>
      <p>
        It is not uncommon to see a color marked on the AKC paperwork for a
        young puppy that is completely different from the adult coat color. The
        way you can tell if a puppy’s coat is going to fade is by checking the
        undercoat. This is my little Redman as a baby and as you can see he was
        a solid red. Then as he grew his coat started to lighten. I knew he
        would be a cream adult (like his daddy) and that is what I wanted so no
        surprise. However, his brother remained a strong, solid mahogany red
        that is gorgeous as well. So many reds to choose from and some are very
        rich, deep and beautiful while others are light even resembling
        strawberry blond.
      </p>

      <LightBox>
        <div className="puppies">
          {images?.map((image, index) => (
            <a href={getImage(image)} key={index}>
              <img src={getImage(image)} alt="" />
            </a>
          ))}
        </div>
      </LightBox>

      <h3 id="patterns">Patterns</h3>
      <p>
        There are numerous descriptions of the way colors fall on the coat. Here
        are just a few:
      </p>
      <ul>
        <li>
          Blaze: This would be a strip of white hair right up the center of the
          face, between the eyes.
        </li>
        <li>
          Flare: This happens when the blaze widens as it approaches the top of
          the skull.
        </li>
        <li>
          Collar or shawl: You will normally see this marking around the neck
          and it is usually white.
        </li>
        <li>
          Saddle: A large patch of color over the back where a saddle would go
          if it were a horse.
        </li>
        <li>
          Parti: Spotted or piebald pattern or color patches interspersed on a
          white background. This pattern can carry the extreme white spotting
          ie., black/white, gold/white, red/white etc., known as the parti coat.
          You will see this pattern often on the AKC Champion Shih Tzus.
        </li>
        <li>
          Tuxedo: Here you may see a white patch of hair on the chest of an
          otherwise solid colored dog. The color is often found on the chin and
          sometimes on the feet. This design resembles a Tuxedo!
        </li>
      </ul>
    </div>
    <Contact image={contact} />
  </div>
);
