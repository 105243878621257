import React from "react";

import "./style.scss";
import { getImage, parseToHtml } from "../../helper";
import { Contact, LightBox } from "../../components";

export const HomeLayout = ({
  title = "",
  pictures = [],
  html = "",
  content = "",
  contact = "",
}) => (
  <div className="home">
    {pictures?.length ? (
      <LightBox>
        <div className="puppies">
          {pictures?.map((puppy, index) => (
            <a href={getImage(puppy?.picture)} key={index}>
              <img src={getImage(puppy?.picture)} alt={title} />
            </a>
          ))}
        </div>
      </LightBox>
    ) : null}
    {title && <h1>{title}</h1>}
    {(content || html) && (
      <div className="data">{parseToHtml(content || html)}</div>
    )}
    <Contact image={contact} />
  </div>
);
